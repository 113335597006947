<template>
  <div class="mobile-contact">
    <div class="mobile-contact__details">
      <span class="mobile-contact__details--label">{{
        trans("about_us_contact_phone")
      }}</span>
      <span class="mobile-contact__details--nr">{{
        config("company_phone")
      }}</span>
    </div>
    <div class="mobile-contect__action">
      <mobile-button
        icon="$phone"
        :text="trans('about_us_contact_call_us_button')"
        background="#78c800"
      />
    </div>
  </div>
</template>

<script>
import mobilePreviewActions from "@/calendesk/mixins/mobilePreviewActions";
import MobileButton from "./MobileButton.vue";

export default {
  name: "MobileContact",
  components: { MobileButton },
  mixins: [mobilePreviewActions],
};
</script>

<style lang="scss" scoped>
.mobile-contact {
  display: flex;
  align-items: center;
  padding: 0.75rem;
}

.mobile-contact__details {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  font-size: 10px;
}

.mobile-contect__action {
  width: 100%;
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.mobile-contect__action--text {
  padding-left: 0.75rem;
}
</style>
