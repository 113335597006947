<template>
  <div class="mobile-work-hours">
    <div v-for="(day, index) in week" :key="index" class="mobile-work-hour">
      <span class="mobile-work-hour__day">{{ day.days }}</span>
      <span v-if="day.hours" class="mobile-work-hour__hours">{{
        day.hours
      }}</span>
      <span v-else class="mobile-work-hour__hours">{{ $trans("closed") }}</span>
    </div>
  </div>
</template>

<script>
export default {
  name: "MobileWorkHours",
  props: {
    days: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      week: [],
    };
  },
  mounted() {
    this.convertDays();
  },
  methods: {
    convertDays() {
      for (const [value] of Object.entries(this.days)) {
        for (const [days, hours] of Object.entries(this.days[value])) {
          const splitDay = days.split("-");

          let element = "";
          if (typeof splitDay[1] === "string") {
            element = `${this.$trans(splitDay[0])}-${this.$trans(splitDay[1])}`;
          } else {
            element = `${this.$trans(splitDay[0])}`;
          }

          this.week.push({
            days: element,
            hours,
          });
        }
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.mobile-work-hours {
  width: 100%;
}

.mobile-work-hour {
  width: 100%;
  height: 55px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1rem;
  border-bottom: solid 1px #dadada;
}

.mobile-work-hour__day {
  width: 100%;
  display: flex;
  justify-content: flex-start;
  font-weight: 300;
  font-size: 10px;
}

.mobile-work-hour__hours {
  width: 100%;
  display: flex;
  justify-content: flex-end;
  font-weight: 500;
  font-size: 12px;
}
</style>
