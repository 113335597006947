<template>
  <div class="mobile-social-media">
    <template v-for="(socialMedia, index) in socialMediaLink">
      <div
        v-if="socialMedia.display"
        :key="index"
        class="mobile-social-media__item"
      >
        <v-img
          :src="require(`@/assets/mobile-preview/${socialMedia.name}.png`)"
          contain
          max-height="20"
          max-width="20"
        />
      </div>
    </template>
  </div>
</template>

<script>
import mobilePreviewActions from "@/calendesk/mixins/mobilePreviewActions";

export default {
  name: "MobileSocialMedia",
  mixins: [mobilePreviewActions],
  computed: {
    socialMediaLink() {
      return [
        {
          name: "facebook",
          display: this.config("facebook"),
        },
        {
          name: "twitter",
          display: this.config("twitter"),
        },
        {
          name: "instagram",
          display: this.config("instagram"),
        },
        {
          name: "youtube",
          display: this.config("youtube"),
        },
      ];
    },
  },
};
</script>

<style lang="scss" scoped>
.mobile-social-media {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.mobile-social-media__item {
  height: 45px;
  width: 45px;
  border-radius: 50%;
  border: solid 1px #dadada;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 0.2rem;
}
</style>
