<template>
  <div class="mobile-app">
    <template v-if="config('company_address')">
      <mobile-subheading :title="trans('about_us_location_title')" />
      <v-container>
        <v-row no-gutters class="mb-4">
          <v-col cols="12">
            <v-img
              :src="`${config('image_base_url')}${
                config('company_location').name
              }`"
              max-height="200"
            />
          </v-col>
        </v-row>
        <v-row no-gutters>
          <v-col cols="12" class="mobile-about-view__location">
            <div class="mobile-about-view__location--details">
              <span
                ><strong>{{ config("company_name") }}</strong></span
              >
              <span> {{ config("company_address") }}</span>
            </div>
            <div class="mobile-about-view__location--action">
              <mobile-button
                background="#78c800"
                icon="$navigation"
                :icon-rotation="45"
                :text="trans('about_us_route')"
              />
            </div>
          </v-col>
        </v-row>
      </v-container>
    </template>
    <template v-if="trans('about_us_text')">
      <mobile-subheading :title="trans('about_us_about_us_title')" />
      <mobile-company-image class="mb-4" />
      <v-container>
        <v-row no-gutters class="mb-4">
          <v-col cols="12">
            <span class="about-text">{{ trans("about_us_text") }}</span>
          </v-col>
        </v-row>
        <v-row no-gutters class="mb-4">
          <v-col cols="12">
            <mobile-social-media />
          </v-col>
        </v-row>
      </v-container>
    </template>
    <mobile-subheading :title="trans('about_us_employees_title')" />
    <div class="mobile-app__employees-carousel">
      <template v-for="(employee, index) in getEmployees">
        <mobile-employee-card
          :key="index"
          :employee="employee"
          class="my-4 ml-4"
        />
        <mobile-employee-card
          :key="index + '12'"
          :employee="employee"
          class="my-4 ml-4"
        />
      </template>
    </div>
    <mobile-subheading
      v-if="config('company_phone') && showCompanyWorkingDays"
      :title="trans('about_us_contact_title')"
    />
    <mobile-contact v-if="config('company_phone')" />
    <mobile-work-hours
      v-if="showCompanyWorkingDays"
      :days="$config('display_company_working_hours')"
    />
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import mobilePreviewActions from "@/calendesk/mixins/mobilePreviewActions";
import MobileSubheading from "../components/MobileSubheading.vue";
import MobileCompanyImage from "../components/MobileCompanyImage.vue";
import MobileSocialMedia from "../components/MobileSocialMedia.vue";
import MobileEmployeeCard from "../components/MobileEmployeeCard.vue";
import MobileContact from "../components/MobileContact.vue";
import MobileWorkHours from "../components/MobileWorkHours.vue";
import MobileButton from "../components/MobileButton.vue";

export default {
  name: "MobileAboutUsView",
  components: {
    MobileSubheading,
    MobileCompanyImage,
    MobileSocialMedia,
    MobileEmployeeCard,
    MobileContact,
    MobileWorkHours,
    MobileButton,
  },
  mixins: [mobilePreviewActions],
  computed: {
    ...mapGetters({
      getEmployees: "mobileEditor/getEmployees",
    }),
    showCompanyWorkingDays() {
      const workingHours = this.$config("display_company_working_hours");
      return !(workingHours[0]["monday-sunday"] === null);
    },
  },
};
</script>

<style lang="scss" scoped>
.mobile-app {
  width: 100%;
  height: 100%;
  overflow-y: scroll;
  overflow-x: hidden;
  background: white;
  padding-bottom: 1rem;
  scrollbar-width: none;

  &::-webkit-scrollbar {
    display: none;
  }
}

.mobile-about-view__location {
  display: flex;
  justify-content: space-between;
}

.mobile-about-view__location--details {
  font-size: 10px;
}

.mobile-about-view__location--action---text {
  margin-left: 0.5rem;
}

.v-icon {
  transform: rotate(45deg);
}

.about-text {
  font-size: 14px;
}

.mobile-app__employees-carousel {
  display: flex;
  overflow: hidden;
}
</style>
