<template>
  <div class="mobile-company-image" :style="backgroundImg">
    <div
      class="mobile-company-image__background-color"
      :style="backgroundColor"
    >
      <v-container>
        <v-row no-gutters>
          <v-col cols="6" offset="3" class="mobile-company-image__item">
            <v-img class="logo shrink" contain :src="getLogoSRC" />
          </v-col>
        </v-row>
      </v-container>
    </div>
  </div>
</template>

<script>
import mobilePreviewActions from "@/calendesk/mixins/mobilePreviewActions";

export default {
  name: "MobileCompanyImage",
  mixins: [mobilePreviewActions],
  computed: {
    backgroundImg() {
      if (!this.config("tenants_mobile_image_about_us")) return {};
      return {
        backgroundImage: `url(${
          this.config("image_base_url") +
          this.config("tenants_mobile_image_about_us").name
        })`,
      };
    },
    backgroundColor() {
      return {
        backgroundColor: this.$helpers.hexToRgba(
          this.config("tenants_mobile_color_primary"),
          0.3
        ),
      };
    },
    getLogoSRC() {
      if (!this.config("tenants_mobile_image_logo")) return;
      return (
        this.config("image_base_url") +
        this.config("tenants_mobile_image_logo").name
      );
    },
  },
};
</script>

<style lang="scss" scoped>
.mobile-company-image {
  width: 100%;
  height: 150px;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

.mobile-company-image__background-color {
  width: 100%;
  height: 100%;
}

.container {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.mobile-company-image__item {
  display: flex;
  justify-content: center;
  align-items: center;
}
</style>
