<template>
  <v-container class="mobile-employee-card">
    <v-img
      src="https://media.calendesk.com/demo/c0-employee-2.jpg"
      contain
      class="mobile-employee-card__avatar mb-4"
    />
    <span class="full-name mb-2"
      >{{ employee.user.name }} {{ employee.user.surname }}</span
    >
    <span class="profesion mb-2">{{ employee.title }}</span>
    <span class="description">{{ employee.description }}</span>
  </v-container>
</template>

<script>
export default {
  name: "MobileEmployeeCard",
  props: {
    employee: {
      type: Object,
      default: () => ({}),
    },
  },
};
</script>

<style lang="scss" scoped>
.mobile-employee-card {
  min-width: 200px;
  width: 200px;
  min-height: 250px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  background: #f5f5f5;
}

.mobile-employee-card__avatar {
  max-height: 86px;
  max-width: 86px;
  border-radius: 50%;
}

.full-name {
  color: #1d1d1d;
  font-weight: 500;
}

.profesion {
  color: #5f5f5f;
  font-weight: 400;
}

.description {
  color: #1d1d1d;
  font-weight: 300;
}
</style>
